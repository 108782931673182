.root {
}

.window {
  display:       inline-block;
  font-size:     1em;
  width:         450px;
  height:        fit-content;

  position:      fixed;
  top:           50%;
  left:          50%;
  transform:     translate(-50%, -50%);

  margin:        auto;
  padding:       30px;
  overflow:      auto;
  z-index:       6000;
  border-radius: 10px;

  outline:       none;
  color:         #FFFFFF;
  border:        1px solid #FFFF00;
  caret-color:   #FF00FF;

  text-align:    center;
  
 /* background:            rgba(0, 0, 0, 0.5); */
  backdrop-filter:       blur(10px); /* Effet de flou */

  background-color: var(--default-opaque-bg-color, rgba(0, 0, 0, 0.5));
}

.interzone {
 width: 100%;
 height: 10px;
}

@media only screen and (max-width: 430px) {
 .window {
     width:      80%;
  }
}


.button{
  height:     30px;
  width:      fit-content;
  font-size:  12px;
  padding:    0 5px;
  margin:     5px;
}

.message{
 text-align: center;
 text-wrap: wrap;
 margin-left: 10px;
 margin-right: 10px;
}

.whiteMessage{
 color:#FFFFFF;
}

.blackMessage{
 color:#000000;
}

.redMessage{
 color:#FF0000;
}

.greenMessage{
 color:#00FF00;
}

.text_confirmation {

  color:                 #FFFFFF;
  font-size:             16px;
  text-align:            justify;
}

.grid_confirmation
 {
  width:                 100%;
  pading:                2px;
  margin:                0;
  font-size:             16px;
  
  display:               grid;
  grid-template-columns: 25px 1fr;
  grid-gap:              5px;
  grid-template-rows:    auto;
}