.root {
  height: 480px;
  color: #FFFFFF;
  margin-top:    20px;
  margin-bottom: 20px;
  transition: background-image 1s ease;
  overflow: hidden;  
  background-color : #141414;
}


.contain {
  width:                 100%;
  pading:                0;
  margin:                0;
  display:               grid;
  grid-template-columns: 40% 1fr;
  grid-gap:              0px;
  grid-template-rows:    auto;
  
}

.left {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        1;
  color:           #FFFFFF;
  overflow: hidden;
}

.right {
  width:           100%;
  display:         flex;
  grid-column:     2;
  grid-row:        1;
}


@media only screen and (max-width: 900px) {

.root {
  height: fit-content;
}

.contain {
  height: fit-content;
  grid-template-columns: 100%;
  grid-gap:              0px;
  grid-template-rows:    auto;
  
}

.left {
  width:           100%;
  height:          300px;
  grid-column:     1;
  grid-row:        1;
}

.right {
  width:           100%;
  height:          fit-content;
  grid-column:     1;
  grid-row:        2;
}

}





.description_frame {

  position: relative;
  width:           100%;
  height:           100%;

}

.gradient_left {

   position: absolute;
    top: 0px;
    left: 0px;
    width: 300px; 
    height: 100%;
    background: linear-gradient(to right, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
	color: #FF0000;
    display: block;
    justify-content: center;
    align-items: center;
}

.gradient_right {

   position: absolute;
    top: 0px;
    right: 0px;
    width: 300px; 
    height: 100%;
    background: linear-gradient(to left, rgba(0, 0, 0, 1), rgba(0, 0, 0, 0));
	color: #FF0000;
    display: block;
    justify-content: center;
    align-items: center;
}

.image {

  width: 100px;
  height: auto;
  margin: auto;
}


.animated_div {
  position: absolute;
  display:inline;
  left: 8vw;
  top: 550px;
  width: 35vw;
  height: 240px;
  align-items: center; /* Centrer horizontalement */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.animated_div.visible {
  opacity: 1;
  transform: translateY(-400px);
}

.animated_div.unvisible {
  opacity: 0;
}

@media only screen and (max-width: 1050px) {

  .animated_div {
    top: 150px;
  }
}


.text {
  color: #FFFFFF;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  
  width: 25vw;
}

.logo {
  width: 25vw;
  height: auto;
}

.icon_play {

  margin-left: 7vw;
  width: 3vw;
  height: 3vw;
}

.icon_play:hover {

  cursor: pointer;
}

.icon_wishlist {

  margin-left: 1vw;
  margin-top: 12px;
  width: 2vw;
  height: auto;
}

.icon_info {

  margin-left: 1vw;
  margin-top: 12px;
  width: 2vw;
  height: auto;
}

@media only screen and (max-width: 900px) {

.animated_div {
  position: absolute;
  display:inline;
  left: -100vw;
    top: 150px;
  width: 90vw;
  height: 240px;
  align-items: center; /* Centrer horizontalement */
  transition: opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
}

.animated_div.visible {
  opacity: 1;
  transform: translateX( 110vw);
}

.animated_div.unvisible {
  opacity: 0;
}


.logo {
  width: 50vw;
  height: auto;
}

/*
.gradient_left {

   display: none;
}

.gradient_right {

   display: none;
}
*/
}





.text2 {
  color: #FFFFFF;
  text-align: left;
  font-size: 16px;
  font-weight: bold;
  margin-left: 1vw;
  margin-right: 1vw;
  width: fit-content;
}

.centeredText {
  margin-top: auto; /* Centrer verticalement */
  margin-bottom: auto;
}

.play {
 display: flex;
}

.preview {
  width:854px;
  height:480px;

}


@media only screen and (max-width: 1500px) {

  .preview {

    width:57vw;
    height:32vw;
  }

}


@media only screen and (max-width: 900px) {

  .preview {

    width:90vw;
    height:51vw;
	margin:auto;
  }

}
