@import '../../../config.module.scss';

.root {
  height:                100vh; 
  width:                 100%;

  background:            $page-background-color;
  color:                 $page-text-color;
  
  background-image:      url("https://lovelightfactory.fr/images/fond.jpg");
}

.contain {

  border-radius:         20px;
  border:                1px solid #FFFFFF;
  padding:               30px;

  background:            rgba(0, 0, 0, 0.7);
  backdrop-filter:       blur(2px);

  height:                fit-content; 
  width:                 90%;
  max-width:             400px; 
  margin:                auto;
  
  justify-content:       center;
  align-items:           center; 
  align:                 center;
  place-items:           center;
  text-align:            center;
}

.shiftbox {
 height: 200px;
}


.title{
  color:                 #FFFFFF;
  font-size:             $page-text-size-h1;
  height:                fit-content; 
  width:                 fit-content; 
  margin:                auto;
  font-weight:           700;
  text-align:            left;
}

.button{
  margin:                auto;
  padding:               10px;
}
 
.message_ack{
  color:                 #00FF00;
  text-align:            center;
}

.message_error{
  color:                 #FF0000;
  text-align:            center;
}

.message_wait{
  color:                 #00FF00;
  text-align:            center;
}
 
.forgotten_password {
  text-align:            right;
  color:                 #FFFFFF;
}

.no_account{
  text-align:            center;
  color:                 #FFFFFF;
}

.logo {
  width:                 100%;
}

.frame_label {
 
 color: #FFFFFF;
}