.root {

 width:   302px;
 height:  fit-content;
 display: inline-block;
 padding: 10px;
}

.title {

  color: #FFFFFF;
  font-size: 20px;
}
.product_image {

  margin: auto;
  width: 234px;
  height: 234px;
}

.product_name {

  width: 234px;
  height: 84px;
}

.btn {

 width: fit-content;
 margin: auto;
}

.infos:hover {
 cursor: pointer;
}

.prices {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: auto;
  grid-gap:              10px;
  grid-template-rows:    auto auto;
}

.line_1 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        1;
}

.line_2 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        2;
}

.normal_price {

 font-size: 25px;
 font-weight: bold;
 color:  #FFFFFF;
 margin: auto;
}


.text {

 color:  #FFFFFF;
 margin: auto;
}


.text2 {

 font-weight: bold;
 color:  #FFFFFF;
 margin: auto;
}
