.root {

 width: 100%;
 display: flex;
 flex-direction: column;
 height: fit-content;
  padding-left: 20px;
  padding-right: 20px; 
}

.carousel {

  display: inline;
  width:95%;
  margin:auto;
}

.title {

  padding-left: 60px;
  text-align: left;
  color: #FFFFFF;
  font-size: 36px;
  font-weight: bold;
}
