.root {

 width:   302px;
 display: inline-block;
 height:  180px;
 padding: 10px;
}

.title {

  color: #FFFFFF;
  font-size: 20px;
}

.image {

  width: 282px;
  height: 158px;

}
