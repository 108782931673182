.block {

  display: inline;
}

.button {

  margin:  auto;
  padding: 10px;

  display: flex; /* Utilisation de Flexbox pour aligner les éléments horizontalement */
  align-items: center; /* Alignement vertical au centre */
  background: #eee;
  border: 1px solid #000;
  border-radius: 5px; /* Simplification pour tous les coins */
  color: #000;
  cursor: pointer;
  outline: none;
}

.infos {

  width: fit-content;
  margin: auto;
  color: #FFFFFF;
}
