.root {

  width:         fit-content;
  heigh: fit-content;
  
  position:      fixed;
  top:           50%;
  left:          50%;
  transform:     translate(-50%, -50%);

  z-index:       2010;
  
/*  background:    #000000; */
  
  background:            rgba(0, 0, 0, 0.5);
  backdrop-filter:       blur(10px); /* Effet de flou */
}

@media only screen and (max-width: 850px) {

  .root {

    position:      fixed;

    width:         90vw;
    height:        85vh;
  
    left:          5vw;
    top:           100px;

    z-index:       2000;
  
    background:    #000000;
  
    overflow: auto;
  
    transform: none; /* Réinitialise la transformation */
  }
 
}

.grid_1 {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: 800px;
  grid-gap:              10px;
  grid-template-rows:    auto;
}

@media only screen and (max-width: 850px) {

  .grid_1 {
    width:                 100%;
    pading:                0;
    margin:                0;
  
    display:               grid;
    grid-template-columns: 100%;
    grid-gap:              10px;
    grid-template-rows:    auto;
  }
}

.block_image{
 margin: auto;
 height: 400px;
 width: 400px;
}

.grid_1_line_1 {
  width:           100%;
  height: fit-content;
  display:         inline;
  grid-column:     1;
  grid-row:        1;
}

.grid_1_line_2 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        2;
}

.grid_2 {
  width:                 100%;
  heigh: fit-content;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: auto;
  grid-gap:              10px;
  grid-template-rows:    auto auto auto;
}

.grid_2_line_1 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        1;
}

.grid_2_line_2 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        2;
}


.grid_2_line_3 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        3;
}

.grid_2_line_4 {
  width:           100%;
  display:         flex;
  grid-column:     1;
  grid-row:        4;
}

.bg_gradient {

/*   background: linear-gradient(to bottom, #000000, #616161); */
}

.image{
  margin: auto;
  width:  400px;
  height: 400px;
}

@media only screen and (max-width: 850px) {

.image{
  margin: auto;
  width:  100%;
  height: auto;
}
}
.numberOfViews {

 margin: auto;
 color: #FFFFFF
}

.ranking {

 margin: auto;
}

.duration {

 color:       #FFFFFF;
 padding:     10px;
 text-align:  justify;
 font-size:   20px;
 font-weight: bold;
}

@media only screen and (max-width: 850px) {

  .duration {
    text-align:  center;
  }
}

.title {

 margin: auto;
 padding:        0px;
 color:          #FFFFFF;
 text-align:     center;
 font-size:      20px;
 font-weight:    bold;
}

.complementToTheTitle {

 padding:       0px;
 padding-left:  10px;
 padding-right: 10px;
 color:         #FFFFFF;
 text-align:    justify;
}

@media only screen and (max-width: 850px) {

  .title {
    text-align: left;
  }

  .complementToTheTitle {
    text-align: left;
  }
}

.description {

 padding:       0px;
 padding-left:  10px;
 padding-right: 10px;
 color:         #FFFFFF;
 text-wrap:     wrap;
 text-align:    justify;
 overflow:      auto; 
 height:        300px;
}

@media only screen and (max-width: 850px) {

  .description {

   padding:       0px;
   padding-left:  10px;
   padding-right: 10px;
   color:         #FFFFFF;
   text-wrap:     wrap;
   text-align:    justify;
   height:        fit-content;
   overflow:      none; 
  }

}

.distribution {

 padding:    10px;
 text-wrap:  wrap;
 text-align: justify;
}

.label_distribution {

 color: #6D6D6D;
}

.value_distribution {

 color: #FFFFFF;
}

.production {

 padding:    10px;
 text-wrap:  wrap;
 text-align: justify;
}

.label_production {

 color: #6D6D6D;
}

.value_production {

 color: #FFFFFF;
}

.copyright {

 padding:    10px;
 text-wrap:  wrap;
 text-align: justify;
}

.label_copyright {

 color: #6D6D6D;
}

.value_copyright {

 color: #FFFFFF;
}

.button_read {

 padding: 10px;
 margin: auto;
}

.button_vote {

 margin: auto;
}


.read_button {
 width:  fit-content;
 height: fit-content;
}


.description {

  max-height: 270px;
  overflow: auto;
}


.btn {

 margin: auto;
 margin-bottom: 10px;
}


.variant_selector {
 width: fit-content;
 margin: auto;
}