.root {
}

.title {
  color:       #FFFFFF;
  text-align:  center;
  font-weight: bold;
}

.informations {
  color: #FFFFFF;
  font-size: 16px;
}

.payments_list {
}

.payments_row {
  display:               grid;
  grid-template-columns: 170px auto 90px 190px;
  grid-gap:              2px;
  grid-template-rows:    auto;
}

.ok {
 color: #00A000;
}

.error {
 color: #FF0000;
}

.infos {
 color: #FFFFFF;
}

.payments_date {
  grid-column:     1;
  grid-row:        1;
}

.payments_description {
  grid-column:     2;
  grid-row:        1;
}

.payments_amount {
  grid-column:     3;
  grid-row:        1;
  text-align:  right;

}

.payments_means_of_payment {
  grid-column:     4;
  grid-row:        1;
}
/*
.payments_status {
  grid-column:     5;
  grid-row:        1;
}
		   
.payments_reference_number {
  grid-column:     6;
  grid-row:        1;
}			   
*/
