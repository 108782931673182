.root {
}


@keyframes zoomEffect {
  from {
    top: var(--topThumbnail);
    left: var(--leftThumbnail);
    width: var(--widthThumbnail);
    height: var(--heightThumbnail);
  }
  to {
    top: var(--topDiv);
    left: var(--leftDiv);
    width: var(--widthDiv);
    height: var(--heightDiv);
  }
}

@keyframes zoomEffect2 {
  from {
    top: var(--topThumbnail);
    left: var(--leftThumbnail);
    width: var(--widthThumbnail);
    height: var(--heightThumbnail);
  }
  to {
    top: var(--topDiv);
    left: 5vw;
    width: 90vw;
    height: var(--heightDiv);
  }
}

.animatedZoom {
  animation: zoomEffect 0.3s forwards;
}

@media only screen and (max-width: 500px) {

.animatedZoom {
  animation: zoomEffect2 0.3s forwards;
}

}


.grid {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: auto;
  grid-gap:              0px;
  grid-template-rows:    auto;
}

.part_1 {
  grid-column:     1;
  grid-row:        1;
}

.part_1b {
  grid-column:     1;
  grid-row:        1;
}

.part_2 {
  display: grid;
  grid-column:     1;
  grid-row:        2;
  opacity: 0; /* Commencez avec une opacité de 0 */
  transition: opacity 0.3s ease-out; /* Animation de l'opacité */
}

.part_2.show {
  opacity: 1; /* Lorsque .show est ajouté, part_2 devient pleinement visible */
}

.bg_gradient {
  background: linear-gradient(to bottom, #000000, #616161);
}

.description {
  width:                 100%;
  pading:                0;
  margin:                0;
  
  display:               grid;
  grid-template-columns: auto 80px 50px;
  grid-gap:              0px;
  grid-template-rows:    auto 35px;
}


.line_1 {
  grid-column:     1/4;
  grid-row:        1;
  min-height:      4px;
}

.col_2_left {
  grid-column:     1;
  grid-row:        2;

}

.col_2_middle {
  grid-column:     2;
  grid-row:        2;
}

.col_2_right {
  grid-column:     3;
  grid-row:        2;
}

.text {
  color: #FFFFFF
}

.title {
  text-align:            center;
  place-items:           center;
  align:                 center;
  margin-left:           5px;
  margin-right:          5px;
}

.buttons {
  display:               flex;
  text-align:            left;
  place-items:           left;
  align:                 left;
  margin-left:           5px;
  margin-right:          auto;
  margin-botton:         5px;
}

.duration {
  text-align:            right;
  place-items:           right;
  align:                 right;
  margin-top:            4px;
  margin-left:           auto;
  margin-right:          10px;
}

.buttonToSheet {
  text-align:            right;
  place-items:           right;
  align:                 right;
  margin-right:           5px;
  margin-left:            auto;
  margin-botton:          5px;
}


.iconToSheet {

 height: 30px;
 width: 30px;
 margin: auto;
 padding: 0px;
}


/* styles.css */
@keyframes zoomAnimation {
  0% {
    width: 100px;
    height: 50px;
  }
  100% {
    width: 150px; /* Ratio de 1.5 */
    height: 100px; /* Ratio de 2 */
  }
}

.zoom {
  animation: zoomAnimation 2s forwards;
}

.read_button {
 width:  fit-content;
 height: fit-content;
}

.bold_text {

  font-weight: bold;
}


.image {

  width: 100%;   /* 282 * 1.3 */
  height: 220px;  /* 158.53 * 1.3 */

}
